<script setup lang="ts">
import { PropType } from 'nuxt/dist/app/compat/vue-demi'
import { storeToRefs } from 'pinia'
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'
import { EventType } from '~/submodules/sharedTypes/common/Event'
import { UserflowId } from '~~/src/constants/UserflowId'
import { useToggle } from '~~/src/hooks/useToggle'
import { useAccommodationsStore } from '~~/src/store/accommodations'
import { useDateDetailsStore } from '~~/src/store/dateDetails'
import { useMobileSheetsStore } from '~~/src/store/mobileSheets'
import { usePricesStore } from '~~/src/store/prices'
import { Price } from '~~/src/submodules/sharedTypes/common/Price'
import { trackPriceDetailSave } from '~~/src/tracking/priceDetailTracking'
import { GenericMobileSheetConfiguration } from '~~/src/types/GenericMobileSheetConfiguration'
import { utilDate } from '~~/src/utils/utilDate'

const props = defineProps({
	config: { type: Object as PropType<GenericMobileSheetConfiguration>, required: true },
})
const { config } = toRefs(props)

const dateDetailsStore = useDateDetailsStore()
const accommodationStore = useAccommodationsStore()
const pricesStore = usePricesStore()
const mobileSheetsStore = useMobileSheetsStore()
const minimumStayStore = useMinimumStayStore()

const { showOrphanNightsFixedDate } = storeToRefs(minimumStayStore)

const currency = computed(() => dateDetailsStore?.currency)
const currentPrice = computed(() => dateDetailsStore.currentPrice)
const isVirtual = computed(() => currentPrice?.value?.isRoomTypeVirtual)
const roomType = computed(() => accommodationStore.getRoomTypeById(currentPrice?.value?.roomTypeId))
const roomUnits = computed(() => roomType.value?.numUnits || 0)
const onDateChange = (date: Date) =>
	dateDetailsStore.setDetailsRequirements(
		dateDetailsStore.accommodationId,
		dateDetailsStore.roomTypeId,
		date,
		undefined
	)
const smartPricingPrice = computed(() => currentPrice?.value?.predictedPrice || 0)
const userPrice = computed(() => currentPrice?.value?.publishedPrice || 0)
const isPastDataNotAvailable = computed(() => {
	const oldestSoldNightDate = roomType.value?.oldestSoldNightDate
	if (!oldestSoldNightDate || oldestSoldNightDate > dateDetailsStore.date) {
		return true
	}

	const date = new Date(dateDetailsStore.date.getTime())
	const oneYearFromSelectedDate = new Date(date.setFullYear(date.getFullYear() - 1))
	return oldestSoldNightDate > oneYearFromSelectedDate
})

const onModifiersSave = (price: Price) => {
	pricesStore.updateModifiers(dateDetailsStore.date, price?.modifiers || {}, dateDetailsStore.roomTypeId)
	const modifiers = price?.modifiers || {}

	trackPriceDetailSave(price.roomTypeId, dateDetailsStore.date, modifiers)
}

const { setToggle: setOccupancyAdrCardToggle, toggleValue: occupancyAdrtoggleValue } = useToggle(false)
const { setToggle: setPriceRecapToggle, toggleValue: priceRecapToggleValue } = useToggle(false)
const { setToggle: setFooterToggle, toggleValue: footerToggleValue } = useToggle(false)

const configToForward: ComputedRef<GenericMobileSheetConfiguration> = computed(() => ({
	...config.value,
	label: { key: accommodationStore.getAccommodationById(dateDetailsStore.accommodationId)?.name as TranslationKey },
}))

onMounted(() => {
	if (roomType.value) {
		accommodationStore.requestRoomTypeSoldNightsRange(roomType.value.id)
	}
})
const showEventsPill = computed(() => dateDetailsStore.events.length > 0)
</script>

<template>
	<MobileSheetsGeneric :config="configToForward">
		<template v-slot:header>
			<div class="flex flex-col">
				<CalendarPicker class="px-4" :date="dateDetailsStore.date" @change="onDateChange" />
				<CalendarLinePicker :current-date="dateDetailsStore.date" @change="onDateChange" />
			</div>
		</template>
		<template v-slot:default>
			<div class="flex flex-col">
				<p v-if="roomType?.treatment" class="mb-1 text-right text-sm text-dark-blue-500">
					{{ useLocale().translate(TranslationKeys.TREATMENT) }}: {{ roomType.treatment.boardType }} | PAX
					{{ roomType.treatment.pax }}
				</p>

				<div class="flex flex-col gap-1 pb-4" v-if="showEventsPill">
					<EventsPill
						v-for="event in dateDetailsStore.events.filter((e) => e.eventType === EventType.Holiday)"
						:custom-event="event"
					/>
					<EventsPill
						v-for="event in dateDetailsStore.events.filter((e) => e.eventType === EventType.Event)"
						:custom-event="event"
					/>
				</div>

				<SettingsSectionsOrphanNightsModifiedStayCard
					:is-orphan-night-fixed="showOrphanNightsFixedDate(roomType?.id, dateDetailsStore.date)"
				/>

				<PriceRecap
					v-if="currentPrice != undefined"
					:price="currentPrice"
					:currency="currency"
					@toggle="setOccupancyAdrCardToggle"
					:expand="occupancyAdrtoggleValue"
				/>

				<PriceStrategyConfrontation
					v-if="smartPricingPrice != userPrice"
					:smart-pricing-price="smartPricingPrice!"
					:currency="currency"
					:user-price="userPrice"
				/>

				<div v-if="!isVirtual" class="fles" :data-id="UserflowId.OccupationAdrCards">
					<PriceOccupancyADRCard
						v-if="roomType?.numUnits != undefined && roomType.numUnits > 0"
						:currency="currency"
						:current-adr="dateDetailsStore.adr"
						:last-year-adr="dateDetailsStore.adrLastYear"
						:occupied-rooms="dateDetailsStore.occupancy"
						:occupied-rooms-last-year="dateDetailsStore.occupancyLastYear"
						:total-rooms="roomUnits"
						@toggle="setPriceRecapToggle"
						:expand="priceRecapToggleValue"
						:hide-last-year-info="isPastDataNotAvailable"
					/>
				</div>
			</div>
		</template>
		<template v-slot:footer>
			<ModifiersCompactCollapsableEditor
				v-if="
					currentPrice != undefined &&
					!utilDate.isBeforeToday(currentPrice.date) &&
					(roomType?.editableModifiers || []).length > 0
				"
				:price="currentPrice"
				@save="onModifiersSave"
				:active-modifiers="currentPrice?.activeModifiers || []"
				:currency="currency"
				confirm-button-data-id="price-detail-modifiers-update"
				:linked-room="currentPrice.indexedPrice"
				@toggle="setFooterToggle"
				:expand="footerToggleValue"
				:modifiers-filters="roomType?.editableModifiers || []"
				:fixed-content-height="mobileSheetsStore.footerHeight"
			/>
		</template>
	</MobileSheetsGeneric>
</template>
